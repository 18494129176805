import React, { useEffect, useRef, useState, SyntheticEvent } from 'react';
import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import Header from '@components/carfax/Header';
import { B16, B32, M12, M16, R12 } from '@styles/global';
import useInput from '@service/hooks/useInput';
import { regExpPhone } from '@service/utils/regExp';
import Input from '@components/common/Input';
import { FlexStyle } from '@styles/carFaxResultStyle';
import MainCompanyCS from '@components/carfax/MainCompanyCS';
import { useMutation } from '@tanstack/react-query';
import { postLogin } from '@api/Auth';
import useUserStore from '@store/userStore';
import { SIGN_UP_RESPONSE } from '@ts-types/auth';
import { isEmpty } from '@service/utils/validation';
import MobileHeader from '@components/carfax/MobileHeader';
import GAHead from '@components/carfax/GAHead';
import { useIsMobile } from '@service/hooks/useIsMobile';

const Login = () => {
  const router = useRouter();

  const passwordInputRef = useRef<HTMLInputElement>(null);
  const phoneInputRef = useRef<HTMLInputElement>(null);
  const [loginError, setLoginError] = useState<boolean>(false);

  const isMobile = useIsMobile();

  const { value: password, onChangeInput: onChangePassword } = useInput();

  const {
    value: phone,
    onChangeInput: onChangePhone,
    isError: isPhoneError,
    isHasError: checkPhoneNum,
  } = useInput({
    regExeForValidationCheck: regExpPhone,
    maxLength: 11,
  });

  const { saveUser } = useUserStore();

  const {
    mutate: mutateLogin,
    isLoading: isLoggingOn,
    error,
  } = useMutation<SIGN_UP_RESPONSE, AxiosError>(
    () => postLogin({ phone, password }),
    {
      onSuccess: (res) => {
        // 로그인 성공
        if (!isEmpty(res?.result)) {
          saveUser({
            id: res.result.id,
            name: res.result.name,
            searchHistory: res.result.searchHistory,
            remainTicketCount: res.result.remainTicketCount,
            token: res.result.token,
          });
        }
        router.push('/');
      },
      onError: (err) => {
        setLoginError(true);
      },
    },
  );

  const submitLogin = () => {
    if (isLoggingOn || password?.length === 0 || phone?.length === 0) return;

    const isError = checkPhoneNum();

    if (!isError && password?.length > 0) {
      mutateLogin();
    }
  };

  return (
    <>
      <GAHead />
      <Container>
        {isMobile ? <MobileHeader /> : <Header />}

        <Wrapper isMobile={isMobile}>
          <h3>로그인</h3>
          <p className='sub'>아이디/비밀번호 찾기는 고객센터로 문의 해주세요.</p>

          <InputContainer isMobile={isMobile}>
            <InputWrapper isMobile={isMobile}>
              <Label>휴대폰 번호</Label>
              <Input
                id='phone_login'
                ref={phoneInputRef}
                value={phone}
                onChange={(e) => {
                  onChangePhone(e);
                  setLoginError(false);
                }}
                type='text'
                inputMode='numeric'
                placeholder='01012341234'
                isError={isPhoneError}
                maxLength={11}
              />
            </InputWrapper>
          </InputContainer>

          <InputContainer isMobile={isMobile}>
            <InputWrapper isMobile={isMobile}>
              <Label>비밀번호</Label>
              <form
                autoComplete='off'
                onSubmit={(e: SyntheticEvent) => {
                  e.preventDefault();
                  submitLogin();
                }}
              >
                <Input
                  autoComplete='off'
                  id='password_login'
                  ref={passwordInputRef}
                  value={password}
                  onChange={(e) => {
                    onChangePassword(e);
                    setLoginError(false);
                  }}
                  type='password'
                  placeholder='비밀번호를 입력해주세요.'
                />
              </form>
            </InputWrapper>
          </InputContainer>

          {isPhoneError && <Error>휴대폰 번호 형식을 확인해 주세요.</Error>}
          {loginError && (
            <Error>휴대전화 번호 혹은 비밀번호가 일치하지 않습니다.</Error>
          )}

          <LoginBtn onClick={submitLogin}>
            <p>로그인</p>
          </LoginBtn>

          {/* <SignUpBtn onClick={() => router.push('/signup')}>
            <h5>
              <span className="green">간편</span> 회원가입
            </h5>
          </SignUpBtn> */}
        </Wrapper>
      </Container>

      <MainCompanyCS isMobile={isMobile} />
    </>
  );
};
export default Login;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f4f4f4;
  position: relative;
  padding-bottom: 240px;
`;

export const Wrapper = styled.div`
  width: 372px;
  margin-top: 160px;

  h3 {
    ${B32}
    color:${({ theme }) => theme.base.black1};
    margin-bottom: 4px;
    text-align: center;
  }

  .sub {
    ${R12}
    font-size:13px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.4px;
    color: ${({ theme }) => theme.base.black1};
    margin-bottom: 24px;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100%;
      height: auto;
      margin-top: 0;
      padding: 0 20px;
    `}
`;

export const Prev = styled.div`
  padding: 18px 20px 20px 0;
  margin-top: 42px;
  position: absolute;
  left: 0;
`;

export const InputContainer = styled.div`
  border-radius: 2px;
  width: 372px;
  display: flex;
  background-color: ${({ theme }) => theme.base.white};
  margin-bottom: 10px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100%;
    `}
`;

export const InputWrapper = styled.div`
  position: relative;
  ${FlexStyle}
  align-items: center;
  width: 100%;

  input {
    border-radius: 2px;
    padding: 16px 135px;
    font-size: 16px;

    ${({ isMobile }) =>
      isMobile &&
      css`
        height: 56px;
        padding: 16px 50px 16px 120px;
      `}

    ::placeholder {
      ${M16}
      line-height:120%;
      color: ${({ theme }) => theme.base.grey400};
    }
  }
`;

const Error = styled.p`
  ${M12}
  color: ${({ theme }) => theme.primary};
  margin: 16px 0 16px 0;
  text-align: center;
`;

export const Label = styled.div`
  position: absolute;
  left: 20px;
  z-index: 1;
  top: 19px;
  ${M12};
  color: ${({ theme }) => theme.base.black1};
`;

export const LoginBtn = styled.button`
  width: 100%;
  padding: 14px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.base.black2};
  ${FlexStyle}
  justify-content: center;
  margin-bottom: 14px;
  cursor: pointer;

  p {
    ${B16}
    color:${({ theme }) => theme.base.white};
    line-height: 140%;
  }
`;

const SignUpBtn = styled.button`
  background-color: transparent;
  padding: 10px;
  width: 100%;
  ${FlexStyle}
  justify-content: center;
  cursor: pointer;

  .green,
  h5 {
    ${B16}
    line-height: 140%;
  }

  .green {
    color: #04d200;
  }
`;
